import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { StepsState } from '@enums/steps-state';
import { StepsInterface } from '@interfaces/steps.interface';
import { TranslateModule } from '@ngx-translate/core';
import { StepperService } from '@services/stepper.service';

import { LinkComponent } from '../../atoms/link/link.component';

@Component({
    selector: 'app-stepper',
    standalone: true,
    templateUrl: './stepper.component.html',
    styleUrls: ['./stepper.component.scss'],
    imports: [CommonModule, TranslateModule, LinkComponent],
})
export class StepperComponent {
    public steps: StepsInterface[] = [];

    public StepsState = StepsState;

    constructor(public stepsService: StepperService) {
        stepsService.getStepperObservable.subscribe((steps) => {
            this.steps = steps;
        });
    }

    backHomePage() {
        window.location.href = 'https://www.nextpass.io';
    }
}
