<div class="content-stepper">
    <div class="flex">
        <h1>{{ 'STEPPER.TITLE' | translate }}</h1>
        <div class="steps">
            <div
                class="step"
                *ngFor="let step of steps"
                [ngClass]="{
                    active: step.state === StepsState.activated,
                    completed: step.state === StepsState.completed,
                    skipped: step.state === StepsState.skipped,
                }"
            >
                <div *ngIf="step.state === StepsState.skipped" class="icon">
                    <img src="../../../../assets/icons/icon-skipped.svg" alt="Skipped" />
                </div>

                <div *ngIf="step.state === StepsState.completed" class="icon">
                    <img src="../../../../assets/icons/icon-completed.svg" alt="Completed" />
                </div>

                <div *ngIf="step.state !== StepsState.completed && step.state !== StepsState.skipped" class="icon">
                    <img src="{{ step.url }}" alt="{{ step.alt }}" />
                </div>
                <div class="info">{{ step.info }}</div>
            </div>
        </div>
    </div>
</div>

<footer>
    <div class="back-to-main">
        <app-link
            [id]="'lnk_stepperBackHomePage'"
            [text]="'STEPPER.LINK_BACK_HOME'"
            (click)="backHomePage()"
            [typeLink]="'light'"
        ></app-link>
    </div>
</footer>
